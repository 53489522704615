export default {
  applePay: {
    merchantName: 'Reverb',
    button: 'Check out with Apple Pay',
  },
  addressBook: {
    cancel: 'Cancel',
    row: {
      actions: {
        change: 'Change',
      },
    },
    listView: {
      header: 'Choose Shipping Address',
      submit: 'Use Address',
    },
    createView: {
      header: 'Add a New Shipping Address',
      submit: 'Save & Use Address',
    },
    editView: {
      header: 'Edit Shipping Address',
      submit: 'Save & Use Address',
    },
  },
  authCallout: {
    signUp: {
      prompt: 'Want all the features Reverb has to offer?',
      buttonText: 'Create an account',
    },
    signIn: {
      prompt: 'Already have a Reverb account?',
      buttonText: 'Log in to save time',
    },
  },
  cart: {
    startCheckout: 'Proceed to Checkout',
    guestUserAuthModalText: "Don't have an account?",
    guestUserAuthModalButtonText: 'Continue as Guest',
  },
  combineAndSaveTooltipText: 'Shipping discounts apply when buying multiple qualified items from this shop',
  checkoutProgressBar: {
    contactInfo: 'Contact Info',
    shipping: 'Shipping',
    payment: 'Payment',
    paypalPayment: 'Authorize PayPal',
    review: 'Place Order',
    confirmation: 'Confirmation',
    authorizePayment: 'Authorize Payment',
  },
  googlePay: {
    total: 'Total',
    estimatedTotal: 'Estimated Total',
  },
  loadingIndicator: {
    oneMomentPlease: 'One moment please',
  },
  orderSummary: {
    cannotBeShippedToAddress: 'Sorry, this listing is not available to ship to your location.',
    protectionPlanUnavailableAtAddress: 'Sorry, purchasing a protection plan is not available at your location.',
    messageSeller: 'Message Seller',
    soldBy: 'Sold by {{shopName}}',
    shipsFrom: 'Ships from {{location}}',
    shippingCost: '{{amount}} Shipping',
    title: 'Order Summary',
    poweredByMulberry: 'Powered by Mulberry',
  },
  orderQuantitySelector: {
    label: 'Quantity',
  },
  orderShippingMethodSelector: {
    label: 'Shipping Method',
  },
  paymentLayout: {
    continueOnKlarna: 'Continue on Klarna',
    changeShippingAddress: 'Change',
  },
  paymentMethod: {
    title: 'Payment Method',
    affirm: {
      nextStep: 'You will be redirected to Affirm to set up your payment.',
      notAvailableForLocalPickup: 'Not available for Local Pickup',
    },
    klarnaAccount: {
      ca: 'Canadian residents only, 18+. See Canadian payment {{linkOpen}}terms{{linkClose}}. A higher initial payment may be required for some consumers.',
      default: 'You must be 18+ to use this credit offer. Klarna uses soft searches with credit reference agencies. These do not affect your credit rating. Missed payments can affect your ability to use Klarna.',
    },
    paypal: {
      payment: 'You will authorize payment via PayPal using a PayPal account or Credit Card, then return to Reverb to complete your purchase.',
      review: "You'll pay via PayPal",
    },
    billingAddress: 'Billing Address',
  },
  redirectReturnLayout: {
    paymentProcessing: 'Processing...',
  },
  paymentInProgressLayout: {
    title: {
      paymentInProgress: 'Placing your order...',
      paymentProcessing: 'Processing...',
    },
  },
  shippingAddress: 'Shipping Address',
  shippingLayout: {
    continueToPayment: 'Continue',
  },
  creditCardCreateForm: {
    editButton: 'Edit',
    cancelEditButton: 'Cancel',
  },
  creditCardDeleteButton: {
    default: 'Delete Credit Card',
    loading: 'Please wait...',
  },
  creditCardSaveButton: {
    default: 'Continue',
    saving: 'Please wait...',
  },
  contactInfo: {
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Email',
  },
  newShippingAddressForm: {
    contactInfo: {
      title: 'Contact Info',
      subtitle: 'So we can send you a receipt & shipping info',
    },
    address: {
      tooltip: 'Add your phone number to stay informed about your package.',
      phoneLabelText: 'Phone Number',
    },
    saveButton: {
      default: 'Continue',
      saving: 'Please wait...',
    },
    editButton: 'Edit',
    cancelEditButton: 'Cancel',
  },
  billingAddressForm: {
    address: {
      tooltip: 'Add your phone number to stay informed about your package.',
      phoneLabelText: 'Phone Number',
    },
    saveButton: {
      default: 'Save Changes',
      saving: 'Saving...',
    },
    cancel: 'Cancel',
    sameAsShipping: 'Same as shipping address',
  },
  buyItNow: {
    title: 'Buy It Now',
    error: 'Something went wrong. Please try again.',
    applePay: 'Apple Pay',
    notInSameCountry: 'This item is shipping to you from {{sellerCountry}}. You may be required to pay import duties (including tariffs) and taxes upon delivery.',
  },
  paypal: {
    error: 'Something went wrong. Please try again.',
    loading: 'Please wait...',
    payWithPayPal: 'Pay With PayPal',
    hostedFields: {
      invalidFields: 'Some fields are invalid',
      cardNumber: 'Card Number',
      fullName: 'Full Name',
      expiration: 'Expiration Date',
      placeOrder: 'Place Order',
      cvvLabel: 'CVV',
      cvvTooltip: '3 or 4 digit security code usually located on the back of the card.',
      agreement: 'By paying with your card, you acknowledge that your data will be processed by PayPal subject to the PayPal Privacy Statement available at {{payPalPrivacyStatementLinkOpen}}PayPal.com{{payPalPrivacyStatementLinkClose}}.',
    },
  },
  paypalPlaceOrderButton: {
    text: {
      default: 'Place Order',
      loading: 'Please wait...',
    },
  },
  expressCheckout: {
    title: 'Express Checkout',
    separatorText: 'or',
  },
  errors: {
    standardUserExists: 'You already have an account on Reverb. Please log in to complete this order.',
  },
  checkoutLayout: {
    saveAddress: 'Save Address',
    addressSaved: 'Saved',
  },
  creditCardWallet: {
    modalButtonChange: 'Change',
    modalButtonSelect: 'Select a card',
  },
  confirmationPage: {
    claimOrderBannerText: 'This email address is associated with an existing Reverb account',
    claimOrderButtonText: 'Sign In to Save Order',
  },
  affirmContinueButton: {
    text: {
      default: 'Check Out with Affirm',
      loading: 'Please wait...',
    },
  },
  cardContinueButton: {
    text: {
      default: 'Place Order',
      loading: 'Please wait...',
    },
  },
  termsAndConditions: "By continuing, you agree to Reverb's {{termsLinkOpen}}Terms{{termsLinkClose}} and {{privacyPolicyLinkOpen}}Privacy Policy{{privacyPolicyLinkClose}}.",
  postPurchaseShopNudge: {
    title: 'Add {{shop}} to your Favorites',
    subtitle: "You'll be the first to know about deals, new listings, and special offers.",
  },
  discountCodeForm: {
    title: 'Coupons',
    manualInput: {
      label: 'Have a Coupon Code?',
      placeholder: 'Coupon code',
      submit: 'Apply',
    },
    buyerCouponList: {
      headerAction: {
        apply: 'Apply',
        applied: 'Applied',
      },
      openPreviewModal: 'See details',
      appliedAndInactiveWarning: 'Coupon could not be applied. Your checkout no longer meets the eligibility requirements.',
      previewModal: {
        header: 'Coupon Details',
        saveButton: 'Apply',
      },
    },
  },
};
